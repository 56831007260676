import React from "react"
import { FormattedMessage } from "react-intl"
import { config } from "../../../../lib/config"

export const ErrorModal: React.FC = () => {
  return (
    <div>
      <p className="text-center text-danger">
        <FormattedMessage
          id="app.modal.easy-apply"
          defaultMessage="Your invite is invalid! It seems you have already created an
    account on MoBerries. If so, please go ahead and log in"
        />{" "}
        <a href={`${config.urls.candidateApp}/login`}>
          <FormattedMessage id="app.common.here" defaultMessage="here" />
        </a>
        .
      </p>
    </div>
  )
}
