import React from "react"
import classNames from "classnames"
import { Media } from "reactstrap"
import Link from "next/link"
import { Job } from "../../lib/types/moberries-entities"
import { Img } from "../shared/img"
import { Salary } from "../shared/salary"
import { JobLink } from "../shared/job-link"
import { FormattedMessage } from "react-intl"

interface DefaultJobCardProps {
  job: Job
  className?: string
}

const DefaultJobCard: React.FC<DefaultJobCardProps> = ({ job, className }) => {
  const { company, title, salaryMin, salaryMax, locations } = job

  return (
    <Media
      className={classNames("box border align-items-center rounded", className)}
    >
      <Media left className="py-3 pl-3">
        <JobLink job={job}>
          <a>
            <Img
              src={company.logo}
              alt={`${company.name} logo`}
              width="80"
              height="80"
              className="mr-3 d-none d-md-inline"
            />
          </a>
        </JobLink>
      </Media>
      <Media body className="py-3">
        <h4>
          <JobLink job={job}>
            <a className="text-body text-decoration-none">{title}</a>
          </JobLink>
        </h4>
        <div className="mb-1">
          <Link href={`/jobs/company/${company.id}`}>
            <a className="text-body py-1 text-decoration-none">
              <span>{company.name}</span>
            </a>
          </Link>
        </div>
        <div className="d-flex flex-wrap">
          {salaryMin > 0 && salaryMax > 0 && (
            <span className="mt-1 mr-4">
              <Salary salaryMax={salaryMax} salaryMin={salaryMin} />
            </span>
          )}
          {locations.slice(0, 3).map(l => (
            <span key={l.placeId} className="mt-1 mr-2">
              <i className="fas fa-map-marker-alt" /> {l.name}
            </span>
          ))}

          {locations.length > 3 && (
            <span className="mt-1">
              <FormattedMessage
                id="default.jobCard.andMore"
                defaultMessage="(and {count} more)"
                values={{
                  count: locations.length - 3,
                }}
              />
            </span>
          )}
        </div>
      </Media>
      <Media right className="align-self-stretch">
        <JobLink job={job}>
          <a className="px-4 h-100 d-flex align-items-center text-decoration-none">
            <i className="fas fa-angle-right fa-lg text-body p-1" />
          </a>
        </JobLink>
      </Media>
    </Media>
  )
}

export default DefaultJobCard
