import { Board } from "./boards"

export enum Locales {
  EN = "en",
  DE = "de",
}

export interface PageMeta {
  title: string
  canonicalUrl: string
  description: string
  keywords: string
  ogImage: string
}

export interface PageProps {
  board: Board
  meta: PageMeta
}

export interface ErrorPageProps {
  errorCode: number
}
