import React, { useContext, useEffect, useState } from "react"
import qs from "qs"

import {
  Input,
  Container,
  Button,
  InputGroup,
  InputGroupAddon,
  Col,
  Row,
  Form,
} from "reactstrap"
import { moberriesApi } from "../../lib/moberries-api"
import { AsyncSelect } from "../shared/async-select"
import { useRouter } from "next/router"
import {
  updateQueryParams,
  getLocationName,
  getRemoteRegionsOptionsList,
  getDefaultCitiesList,
} from "../../lib/helpers"
import { head } from "ramda"
import { defineMessage, useIntl } from "react-intl"
import { CompanyCity } from "../../lib/types/moberries-entities"
import { CompanyGroupContext } from "../../lib/company-group-context"
import { Select } from "../shared/select"

const defaultCities = getDefaultCitiesList()
const remoteRegionsOptions = getRemoteRegionsOptionsList()

const DefaultJobsFilter: React.FC = () => {
  const router = useRouter()
  const [, queryString] = router.asPath.split("?")
  const params = qs.parse(queryString) as ObjectLiteral<string>
  const [location, setLocation] = useState<CompanyCity | null>()
  const [company, setCompany] = useState<string | undefined | null>()
  const [remoteRegions, setRemoteRegions] = useState<
    string | undefined | null
  >()
  const [category, setCategory] = useState<string | undefined | null>()
  const [search, setSearch] = useState<string>(router.query.q as string)
  const companyGroup = useContext(CompanyGroupContext)

  useEffect(() => {
    const fetch = async () => {
      if (!params.company) {
        setCompany(null)
        return
      }
      const { data: company } = await moberriesApi.getCompany({
        id: Number(params.company),
      })
      setCompany(company)
    }
    fetch()
  }, [params.company])
  useEffect(() => {
    if (!params.category) {
      setCategory(null)
      return
    }
    const fetch = async () => {
      const { data: category } = await moberriesApi.getJobRoleCategory({
        id: Number(params.category),
      })
      setCategory(category)
    }
    fetch()
  }, [params.category])
  useEffect(() => {
    if (!params.location) {
      setLocation(null)
      return
    }
    const fetch = async () => {
      const {
        data: { results: cities },
      } = await moberriesApi.getCities({
        placeId: String(params.location),
      })
      setLocation(head(cities))
    }
    fetch()
  }, [params.location])

  const intl = useIntl()

  return (
    <Container>
      <Row>
        <Col xs={12} lg={{ size: 10, offset: 1 }}>
          <Form
            onSubmit={e => {
              e.preventDefault()
              router.push({
                pathname: router.route,
                query: updateQueryParams(params, {
                  q: search ?? null,
                  page: null,
                }),
              })
            }}
            noValidate
          >
            <InputGroup size="lg" className="position-relative">
              <Input
                className="pr-6"
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
                placeholder={intl.formatMessage(
                  defineMessage({
                    id: "default.jobsFilter.search.placeholder",
                    defaultMessage: "Search",
                  }),
                )}
              />
              {search && (
                <i
                  className="fas fa-times text-muted position-absolute mt-3 mr-3 clickable"
                  style={{ right: 50, zIndex: 3 }}
                  onClick={() => {
                    setSearch("")
                    router.push({
                      pathname: router.route,
                      query: updateQueryParams(params, {
                        q: null,
                        page: null,
                      }),
                    })
                  }}
                />
              )}
              <InputGroupAddon addonType="append">
                <Button color="primary">
                  <i className="fas fa-search" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>

          <Row noGutters>
            <Col xs={12} md={4} lg={3} className="pt-1 pr-md-1">
              <AsyncSelect
                id="select-company"
                isClearable
                placeholder={intl.formatMessage(
                  defineMessage({
                    id: "default.jobsFilter.company.placeholder",
                    defaultMessage: "Company",
                  }),
                )}
                getOptionLabel={o => o.name}
                getOptionValue={o => o.id}
                defaultOptions
                value={company}
                loadOptions={async search => {
                  const {
                    data: { results: companies },
                  } = await moberriesApi.getCompanyList({
                    search,
                    ordering: "used_jobs",
                    companyGroup: companyGroup?.id,
                  })
                  return companies
                }}
                onChange={option => {
                  setCompany(option)
                  router.push({
                    pathname: router.route,
                    query: updateQueryParams(params, {
                      company: option?.id ?? null,
                      page: null,
                    }),
                  })
                }}
              />
            </Col>
            <Col xs={12} md={4} lg={3} className="pt-1 pr-md-1">
              <AsyncSelect
                id="select-category"
                isClearable
                placeholder={intl.formatMessage(
                  defineMessage({
                    id: "default.jobsFilter.category.placeholder",
                    defaultMessage: "Category",
                  }),
                )}
                getOptionLabel={o => o.name}
                getOptionValue={o => o.id}
                defaultOptions
                value={category}
                loadOptions={async search => {
                  const {
                    data: { results: categories },
                  } = await moberriesApi.getJobRoleCategoryList({
                    search,
                    companyGroup: companyGroup?.id,
                  })
                  return categories
                }}
                onChange={async option => {
                  setCategory(option)
                  router.push({
                    pathname: router.route,
                    query: updateQueryParams(params, {
                      category: option?.id ?? null,
                      page: null,
                    }),
                  })
                }}
              />
            </Col>
            <Col xs={12} md={4} lg={3} className="pt-1 pr-md-1">
              <AsyncSelect
                id="select-city"
                isClearable
                placeholder={intl.formatMessage(
                  defineMessage({
                    id: "default.jobsFilter.city.placeholder",
                    defaultMessage: "City",
                  }),
                )}
                getOptionLabel={o => getLocationName(o)}
                getOptionValue={o => o.placeId}
                defaultOptions={defaultCities}
                onChange={option => {
                  setLocation(option)
                  router.push({
                    pathname: router.route,
                    query: updateQueryParams(params, {
                      location: option?.placeId ?? null,
                      page: null,
                    }),
                  })
                }}
                value={location}
                loadOptions={async search => {
                  const {
                    data: { results: cities },
                  } = await moberriesApi.getCities({
                    search,
                  })
                  return cities
                }}
              />
            </Col>
            <Col xs={12} md={4} lg={3} className="pt-1">
              <Select
                id="select-remote-regions"
                isClearable
                placeholder="Remote"
                options={remoteRegionsOptions}
                getOptionLabel={o =>
                  intl.formatMessage(
                    defineMessage({
                      id: `default.jobsFilter.remoteRegions.${o.value}`,
                      defaultMessage: o.label,
                    }),
                  )
                }
                value={remoteRegions}
                onChange={option => {
                  setRemoteRegions(option)
                  router.push({
                    pathname: router.route,
                    query: updateQueryParams(params, {
                      remote: option ? "true" : null,
                      remoteRegions: option?.value ?? null,
                      page: null,
                    }),
                  })
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default DefaultJobsFilter
