import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Img } from "../shared/img"

const DefaultInactiveJob: React.FC = () => (
  <Fragment>
    <h1>
      <FormattedMessage
        id="default.inactiveJob.title"
        defaultMessage="Unfortunately, this job is not available anymore."
      />
    </h1>
    <p className="text-muted">
      <FormattedMessage
        id="default.inactiveJob.subtitle"
        defaultMessage="<a>Sign up</a> to receive similar offers and discover new opportunities on our platform."
        values={{
          a: (text: string) => (
            <a
              href="https://app.moberries.com/signup"
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </a>
          ),
        }}
      />
    </p>
    <div className="p-3 text-center">
      <Img
        alt="Empty"
        width="600"
        className="img-fluid"
        src="/web-catalog-app/assets/empty-state.svg"
      />
    </div>
  </Fragment>
)

export default DefaultInactiveJob
