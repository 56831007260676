import dynamic from "next/dynamic"
import DefaultComponents from "../default/default-components"

const BestCareerHeader = dynamic(() => import("./bestcareer-header"))
const BestCareerHero = dynamic(() => import("./bestcareer-hero"))
const BestCareerFooter = dynamic(() => import("./bestcareer-footer"))

const BestCareerComponents = {
  ...DefaultComponents,
  Header: BestCareerHeader,
  Hero: BestCareerHero,
  Footer: BestCareerFooter,
}

export default BestCareerComponents
