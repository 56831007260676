import React from "react"
import Head from "next/head"
import { config } from "../../lib/config"

export const SharedMeta: React.FC = () => {
  return (
    <Head>
      <meta property="og:site_name" content="MoBerries" key="og:site_name" />
      <meta property="og:type" content="website" key="og:type" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=no"
        key="viewport"
      />

      <meta
        property="fb:app_id"
        content={config.facebook.appId}
        key="fb:app_id"
      />
      <meta name="version" content={config.package.version} key="version" />
    </Head>
  )
}
