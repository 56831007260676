import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { LanguageLevels } from "../../lib/types/moberries-entities"

export const LanguageLevel: React.FC<{ level: LanguageLevels }> = ({
  level,
}) => (
  <Fragment>
    {
      {
        [LanguageLevels.Beginner]: (
          <FormattedMessage
            id="shared.languageLevels.beginner"
            defaultMessage="Beginner"
          />
        ),
        [LanguageLevels.Elementary]: (
          <FormattedMessage
            id="shared.languageLevels.elementary"
            defaultMessage="Beginner"
          />
        ),
        [LanguageLevels.Intermediate]: (
          <FormattedMessage
            id="shared.languageLevels.intermediate"
            defaultMessage="Intermediate"
          />
        ),
        [LanguageLevels.UpperIntermediate]: (
          <FormattedMessage
            id="shared.languageLevels.upperIntermediate"
            defaultMessage="Upper intermediate"
          />
        ),
        [LanguageLevels.Advanced]: (
          <FormattedMessage
            id="shared.languageLevels.advanced"
            defaultMessage="Advanced"
          />
        ),
        [LanguageLevels.Mastery]: (
          <FormattedMessage
            id="shared.languageLevels.mastery"
            defaultMessage="Mastery"
          />
        ),
        [LanguageLevels.Native]: (
          <FormattedMessage
            id="shared.languageLevels.native"
            defaultMessage="Native"
          />
        ),
      }[level]
    }
  </Fragment>
)
