import RAsyncSelect, { Props } from "react-select/async"

export const AsyncSelect: React.FC<Props<any>> = ({ id, ...props }) => {
  return (
    <RAsyncSelect
      components={{
        IndicatorSeparator: null,
      }}
      id={id}
      instanceId={id}
      classNamePrefix="select"
      {...props}
    />
  )
}
