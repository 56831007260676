import React, { Fragment } from "react"
import { useOpenClose } from "../../hooks/use-open-close"
import { Popover } from "reactstrap"

export const InfoHint: React.FC<{ id: string; popover: React.ReactNode }> = ({
  id,
  popover,
}) => {
  const { toggle, value: isOpen, open, close } = useOpenClose({
    initialValue: false,
  })

  return (
    <Fragment>
      <i
        id={`info-hint-popover-${id}`}
        onClick={toggle}
        className="fas fa-question-circle pl-1 text-primary clickable"
      />
      <Popover
        placement="top"
        isOpen={isOpen}
        target={`info-hint-popover-${id}`}
        toggle={toggle}
        trigger="legacy"
      >
        {typeof popover === "function"
          ? popover({ open, close, toggle, isOpen })
          : popover}
      </Popover>
    </Fragment>
  )
}
