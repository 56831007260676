import dynamic from "next/dynamic"
import DefaultComponents from "../default/default-components"
const TalentBerlinHeader = dynamic(() => import("./talent-berlin-header"))
const TalentBerlinHero = dynamic(() => import("./talent-berlin-hero"))
const TalentBerlinPartnersWithMostJobsHeader = dynamic(
  () => import("./talent-berlin-partners-with-most-jobs-header"),
)
const TalentBerlinJobList = dynamic(() => import("./talent-berlin-job-list"))
const TalentBerlinJobsFilter = dynamic(
  () => import("./talent-berlin-jobs-filter"),
)
const TalentBerlinTracking = dynamic(() => import("./talent-berlin-tracking"))

const TalentBerlinComponents = {
  ...DefaultComponents,
  Header: TalentBerlinHeader,
  Hero: TalentBerlinHero,
  JobsFilter: TalentBerlinJobsFilter,
  PartnersWithMostJobsHeader: TalentBerlinPartnersWithMostJobsHeader,
  JobList: TalentBerlinJobList,
  Tracking: TalentBerlinTracking,
}

export default TalentBerlinComponents
