import dynamic from "next/dynamic"
import DefaultComponents from "../default/default-components"
const RemotePartnersWithMostJobsHeader = dynamic(
  () => import("./remote-partners-with-most-jobs-header"),
)
const RemoteJobsFilter = dynamic(() => import("./remote-jobs-filter"))
const RemotePartnersWithMostJobs = dynamic(
  () => import("./remote-partners-with-most-jobs"),
)
const RemoteHero = dynamic(() => import("./remote-hero"))
const RemoteTracking = dynamic(() => import("./remote-tracking"))

const RemoteComponents = {
  ...DefaultComponents,
  Hero: RemoteHero,
  PartnersWithMostJobs: RemotePartnersWithMostJobs,
  PartnersWithMostJobsHeader: RemotePartnersWithMostJobsHeader,
  JobsFilter: RemoteJobsFilter,
  Tracking: RemoteTracking,
}

export default RemoteComponents
