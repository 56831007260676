import React from "react"
import { Job } from "../../lib/types/moberries-entities"
import { Row, Col, Container } from "reactstrap"
import { Pagination } from "../shared/pagination"
import { useComponents } from "../../hooks/use-components"
import { Img } from "../shared/img"
import { FormattedMessage } from "react-intl"

interface DefaultJobListProps {
  jobs: Job[]
  jobsCount: number
}

const DefaultJobList: React.FC<DefaultJobListProps> = ({ jobs, jobsCount }) => {
  const { JobCard } = useComponents()
  return (
    <Container tag="section" className="mt-3">
      <Row>
        <Col lg={{ size: 10, offset: 1 }} md={12}>
          <div className="mb-5">
            {jobs.length === 0 && (
              <div className="d-flex flex-column align-items-center py-3 mv-3">
                <span className="display-4 font-weight-bold">
                  <FormattedMessage
                    id="default.jobList.empty.header"
                    defaultMessage="Try again later!"
                  />
                </span>
                <span className="mb-5">
                  <FormattedMessage
                    id="default.jobList.empty.text"
                    defaultMessage="Currently, there are no jobs matching your search."
                  />
                </span>

                <Img
                  src="/web-catalog-app/assets/empty-state.svg"
                  width="70%"
                />
              </div>
            )}
            {jobs.map(j => (
              <JobCard job={j} key={j.id} className="mb-1" />
            ))}
            {jobsCount > 10 && (
              <Pagination
                className="mt-3"
                itemsPerPage={10}
                totalCount={jobsCount}
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default DefaultJobList
