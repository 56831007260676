import qs from "qs"
import { AxiosError } from "axios"
import * as Sentry from "@sentry/nextjs"

export function getCurrentUrl() {
  return `https://${window.location.hostname}`
}

export function updateQueryParams(
  query: ObjectLiteral<string | undefined | null> = {},
  params: ObjectLiteral<string | undefined | null> = {},
  addQueryPrefix = false,
) {
  const updatedQuery = Object.entries({ ...query, ...params }).reduce<
    ObjectLiteral<string | undefined | null>
  >((acc, [key, value]) => {
    if (value !== null && value !== "" && value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {})

  return qs.stringify(updatedQuery, {
    addQueryPrefix,
  })
}

export function sanitizeForUrl(str: string) {
  return str
    .normalize("NFD") // Change diacritics
    .replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
    .replace(/\s+/g, "-") // Change whitespace to dashes
    .toLowerCase() // Change to lowercase
    .replace(/&/g, "-and-") // Replace ampersand
    .replace(/[^a-z0-9-]/g, "") // Remove anything that is not a letter, number or dash
    .replace(/-+/g, "-") // Remove duplicate dashes
    .replace(/^-*/, "") // Remove starting dashes
    .replace(/-*$/, "") // Remove trailing dashes
}

export function getErrorCode(err: Error | AxiosError) {
  let errorCode = null
  if ("response" in err) {
    errorCode = err.response?.status ?? 500
  } else {
    errorCode = 500
  }

  if (errorCode === 500) {
    Sentry.captureException(err)
  }
  return errorCode
}
