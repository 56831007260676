import { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Button, PopoverBody, Row } from "reactstrap"
import { Img } from "../../../shared/img"
import { InfoHint } from "../../../shared/info-hint"
import { LabelWithHint } from "../../../shared/label-with-hint"
import { StepsEnum, StepsInterface } from "../default-easy-apply-modal"
import { StepProgress } from "../steps-progress/step-progress"

interface ConfirmationModalProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<StepsInterface>>
}

const stepsConfig = [
  {
    label: "1",
    active: true,
    passed: false,
  },
  {
    label: "2",
    active: false,
    passed: false,
  },
]

const proceedWithoutCVBtnStyles = {
  height: 21,
  border: "none",
  color: "#0738da",
  background: "none",
  outline: "none",
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  setCurrentStep,
}) => {
  return (
    <Fragment>
      <StepProgress steps={stepsConfig} />
      <div className="d-flex justify-content-between align-items-baseline">
        <LabelWithHint
          label={
            <span className="h5">
              <FormattedMessage
                id="app.profile.cv.label"
                defaultMessage="Upload your CV file"
              />
            </span>
          }
          hint={
            <InfoHint
              id="cvHint"
              popover={
                <PopoverBody>
                  <FormattedMessage
                    id="app.profile.cv.hint"
                    defaultMessage="Upload the most recent version of your CV her. You can always update it in the future. We support PDF and Word files with the max size of 5MB."
                  />
                </PopoverBody>
              }
            />
          }
          isRequired
          for="cvHint"
        />
        <button className="mb-2" style={proceedWithoutCVBtnStyles}>
          <FormattedMessage
            id="app.profile.cv.noCv.button"
            defaultMessage="Proceed without CV"
          />
        </button>
      </div>
      <div className="text-center py-4 align-items-center border d-flex flex-column border-dashed border-wider rounded no-outline">
        <Img
          alt="Warning"
          width="40"
          className="img-fluid"
          src="/web-catalog-app/assets/warning.svg"
        />
        <FormattedMessage
          id="app.profile.cv.noCv.warning"
          defaultMessage="<p>Please note that by applying without a CV you have started the application process by indicating an interest in the job.</p><p>To complete the full application, <b>we will require the CV to send your application for the relevant job.</b></p><p>Should you not have a CV, you can create your own on MoBerries with our CV builder. Once uploaded all pending applications are automatically completed.</p>"
          values={{
            p: (text: string) => <p className="my-2 px-3">{text}</p>,
            b: (text: string) => <b>{text}</b>,
          }}
        />
      </div>
      <Row className="flex justify-content-center mt-5">
        <Button
          color="light"
          className="py-3 px-6 mr-3"
          onClick={() =>
            setCurrentStep(s => ({ from: s.to, to: StepsEnum.cv }))
          }
        >
          <FormattedMessage id="app.common.back" defaultMessage="Back" />
        </Button>
        <Button
          color="primary"
          className="py-3 px-6"
          onClick={() =>
            setCurrentStep(s => ({ from: s.to, to: StepsEnum.details }))
          }
        >
          <FormattedMessage
            id="app.profile.cv.noCv.button"
            defaultMessage="Proceed without CV"
          />
        </Button>
      </Row>
    </Fragment>
  )
}
