import React from "react"
import { FormattedMessage } from "react-intl"

const DefaultPartnersWithMostJobsHeader: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <h1 className={className}>
      <FormattedMessage
        id="default.partnersWithMostJobs.header"
        defaultMessage="Partners with most jobs"
      />
    </h1>
  )
}

export default DefaultPartnersWithMostJobsHeader
