import React from "react"
import Head from "next/head"
import { PageMeta } from "../../lib/types/shared"

const DefaultMeta: React.FC<PageMeta> = ({
  title,
  canonicalUrl,
  description,
  keywords,
  ogImage,
}) => {
  return (
    <Head>
      <title key="title">{title}</title>
      <meta property="og:title" content={title} key="og:title" />

      <link rel="canonical" href={canonicalUrl} key="canonical" />
      <meta property="og:url" content={canonicalUrl} key="og:url" />

      <meta name="description" content={description} key="description" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />

      <meta name="keywords" content={keywords} key="keywords" />
      <meta property="og:keywords" content={keywords} key="og:keywords" />

      <meta property="og:image" content={ogImage} key="og:image" />
    </Head>
  )
}
export default DefaultMeta
