import { useRouter } from "next/router"
import React from "react"
import { useComponents } from "../../hooks/use-components"

const DefaultApplyButton: React.FC = () => {
  const { RegularApplyButton, EasyApplyButton } = useComponents()
  const router = useRouter()
  const { utm_source: utmSource = "" } = router.query

  // TODO: remove when we will have only one utm_source for talent.com
  const source = typeof utmSource === "string" ? utmSource : utmSource[0]

  const isEasyApplyForbidden = [
    "jobg8",
    "joblift",
    "adzuna",
    // TODO: leave only talent-com when Talent.com will start using correct feed.
    "talent.com",
    "talent-com",
    "neuvoo",
  ].includes(source as string)

  if (isEasyApplyForbidden) {
    return <RegularApplyButton />
  }

  return <EasyApplyButton />
}

export default DefaultApplyButton
