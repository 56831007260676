import { Row, Button } from "reactstrap"
import { Fragment } from "react"
import { useRouter } from "next/router"
import { config } from "../../../../lib/config"
import { FormattedMessage } from "react-intl"
import { useIsFromAffiliateInvite } from "../../../../hooks/use-is-from-affiliate-invite"

interface SuccessModalProps {
  onClose: () => void
}

export const SuccessModal: React.FC<SuccessModalProps> = ({ onClose }) => {
  const router = useRouter()
  const isFromAffiliateInvite = useIsFromAffiliateInvite()
  return (
    <Fragment>
      <Row className="d-flex justify-content-center">
        <img
          src="/web-catalog-app/assets/candidate-invitation-success.svg"
          alt="Moberries"
        />
      </Row>
      <Row className="flex justify-content-center mt-5 text-center">
        <h2>
          {" "}
          <FormattedMessage
            id="app.modal.success.headline"
            defaultMessage="Almost there!"
          />
        </h2>

        {isFromAffiliateInvite ? (
          <p className="alert-light">
            <FormattedMessage
              id="app.modal.success.text.direct"
              defaultMessage="Please log into your account on MoBerries to complete your application process."
            />
          </p>
        ) : (
          <p className="alert-light">
            <FormattedMessage
              id="app.modal.success.text.sharing"
              defaultMessage="Please go to your emails and click on the link we sent you to complete your registration."
            />
          </p>
        )}
      </Row>

      <Row className="flex justify-content-center mt-5">
        {isFromAffiliateInvite && (
          <Button
            color="secondary"
            type="button"
            className="py-3 px-6 mr-2 modal_action_btn-success"
            onClick={() => {
              onClose()
              router.push(`${config.urls.candidateApp}/login`)
            }}
          >
            <FormattedMessage
              id="app.modal.success.button"
              defaultMessage="LOGIN"
            />
          </Button>
        )}
      </Row>
    </Fragment>
  )
}
