import React from "react"
import { FormattedMessage } from "react-intl"
import { Job } from "../../lib/types/moberries-entities"
import { JobLink } from "../shared/job-link"

interface DefaultSimilarJobsProps {
  jobs: Job[]
}

const DefaultSimilarJobs: React.FC<DefaultSimilarJobsProps> = ({ jobs }) => {
  return (
    <div className="box rounded border">
      <h5 className="p-3 m-0">
        <FormattedMessage
          id="default.similarJobs.header"
          defaultMessage="These might interest you as well"
        />
      </h5>
      <ul className="list-unstyled m-0">
        {jobs.map(j => (
          <li key={j.id} className="border-top px-3 py-2">
            <div className="d-block">
              <JobLink job={j}>
                <a>{j.title}</a>
              </JobLink>
            </div>
            <small>{j.company.name}</small>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DefaultSimilarJobs
