import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { Row, Button } from "reactstrap"

interface DiscardModalProps {
  onDiscard: () => void
  onBack: () => void
}

export const DiscardModal: React.FC<DiscardModalProps> = ({
  onDiscard,
  onBack,
}) => {
  return (
    <Fragment>
      <Row className="d-flex justify-content-center">
        <img
          src="/web-catalog-app/assets/candidate-invitation-discard.svg"
          alt="Moberries"
        />
      </Row>
      <Row className="flex justify-content-center mt-5 text-center">
        <h2>
          <FormattedMessage
            id="app.modal.discard.headline"
            defaultMessage="Discard the application?"
          />
        </h2>
        <p className="alert-light">
          <FormattedMessage
            id="app.modal.discard.text"
            defaultMessage="Are you sure you want to discard the application process? If you
            choose yes, please be aware, that your application won`t be saved."
          />
        </p>
      </Row>
      <Row className="flex justify-content-center mt-5">
        <Button
          color="secondary"
          type="button"
          className="py-3 px-6 mr-2 modal_action_btn-back"
          onClick={() => {
            onBack()
          }}
        >
          <FormattedMessage id="app.common.back" defaultMessage="Back" />
        </Button>
        <Button
          color="danger"
          type="button"
          className="py-3 px-6 mr-2"
          onClick={() => {
            onDiscard()
          }}
        >
          <FormattedMessage id="app.common.cancel" defaultMessage="Discard" />
        </Button>
      </Row>
    </Fragment>
  )
}
