import { useRouter } from "next/router"

export const useIsFromAffiliateSharedLink = () => {
  const router = useRouter()
  const {
    candidate_id: candidateId,
    id: jobId,
    manager_id: managerId,
  } = router.query

  return !!(jobId && managerId) && !candidateId
}
