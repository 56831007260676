import { StylesConfig } from "react-select/src/styles"
import { merge } from "ramda"

export function getSelectStyles(invalid?: boolean): StylesConfig {
  return {
    placeholder: provided => merge(provided, { display: "none" }),
    container: provided =>
      merge(provided, {
        marginTop: "6px",
        borderRadius: 4,
        height: 48,
        border: "none",
        outline: 0,
      }),
    control: (provided, state) => {
      const { isFocused } = state
      const borderAndOutline = {
        outline: isFocused ? "3px solid #c7d3fa" : "none",
        borderColor: invalid ? "red" : isFocused ? "#6e8ffa" : "#eef0f6",
      }
      const custom = {
        fontWeight: 400,
        borderRadius: 4,
        borderWidth: 2,
        height: 48,
        cursor: isFocused ? "text" : "pointer",
        ...borderAndOutline,
        ":hover": borderAndOutline,
      }
      return merge(provided, custom)
    },
    menu: provided =>
      merge(provided, {
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#c7d3fa",
        marginTop: 3,
        zIndex: 3,
      }),
    option: (provided, state) => {
      const { isFocused, isSelected } = state

      let custom = {
        color: "black",
        cursor: "pointer",
        fontWeight: 400,
      }

      if (isFocused) {
        custom = merge(custom, {
          backgroundColor: "#c7d3fa",
          color: "black",
        })
      }

      if (isSelected) {
        custom = merge(custom, {
          backgroundColor: "#3361e1",
          color: "white",
        })
      }

      return merge(provided, custom)
    },
    indicatorsContainer: provided => merge(provided, { cursor: "pointer" }),
  }
}
