export enum BoardLayoutTypes {
  Lhoft = "lhoft",
  BestCareer = "bestcareer",
  Default = "default",
  Remote = "remote",
  Htgf = "htgf",
  Redstone = "redstone",
  TalentBerlin = "talent-berlin",
}

export enum BoardDataTypes {
  Common = "common",
  Default = "default",
  Remote = "remote",
  TalentBerlin = "talent-berlin",
}

export interface Board {
  layoutType: BoardLayoutTypes
  dataType: BoardDataTypes
  subdomain: string
}
