import React, { Fragment } from "react"
import * as yup from "yup"
import { Button, Col, Row } from "reactstrap"
import { FormattedMessage, useIntl } from "react-intl"

import { LabelWithHint } from "../../../shared/label-with-hint"
import { CompleteAccountFields } from "../details-form/complete-account-fields"
import { StepsEnum, StepsInterface } from "../default-easy-apply-modal"
import { useMutation } from "react-query"
import { moberriesApi } from "../../../../lib/moberries-api"
import { useFormik } from "formik"
import { StepProgress } from "../steps-progress/step-progress"
import { useIsFromAffiliateInvite } from "../../../../hooks/use-is-from-affiliate-invite"
import { isRefferedCandidate } from "../../../../lib/helpers"

interface CompleteAccountModalProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<StepsInterface>>
  data: any
  me: any
}

const stepsConfig = [
  {
    label: "1",
    active: false,
    passed: true,
  },
  {
    label: "2",
    active: true,
    passed: false,
  },
]

export const CompleteAccountModal: React.FC<CompleteAccountModalProps> = ({
  setCurrentStep,
  data,
  me,
}) => {
  const intl = useIntl()

  const acceptInviteMutation = useMutation<any, any, any>(
    "acceptInviteMutation",
    (input: any) =>
      moberriesApi.acceptInvite(
        { data: input },
        {
          Authorization: `JWT ${data.token}`,
        },
      ),
    {
      onSuccess: () => {
        setCurrentStep(s => ({ from: s.to, to: StepsEnum.success }))
      },
      onError: e => {
        setCurrentStep(s => ({ from: s.to, to: StepsEnum.error }))
        console.error(e)
      },
    },
  )

  const isFromAffiliateInvite = useIsFromAffiliateInvite()

  const requiredMessage = intl.formatMessage({
    id: "app.common.required",
    defaultMessage: "Required",
  })

  const acceptInviteSchema = yup.object().shape({
    firstName: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    location: yup
      .object()
      .nullable()
      .test("id-not-null", requiredMessage, val => !!val),
    password1: yup
      .string()
      .min(8, {
        id: intl.formatMessage({
          id: "app.modal.complete.min-pw-length",
          defaultMessage: "Minimum length is 8",
        }),
        values: {
          min: 8,
        },
      })
      .max(
        50,
        intl.formatMessage({
          id: "app.modal.complete.max-pw-length",
          defaultMessage: "Maximum allowed length is 50",
        }),
      )
      .required(requiredMessage),
    password2: yup
      .string()
      .oneOf(
        [yup.ref("password1"), null],
        intl.formatMessage({
          id: "app.modal.complete.pw-match",
          defaultMessage: "Passwords must match",
        }),
      )
      .required(requiredMessage),
    email: yup
      .string()
      .email("Required")
      .max(
        50,
        intl.formatMessage({
          id: "app.modal.complete.max-pw-length",
          defaultMessage: "Maximum allowed length is 50",
        }),
      )
      .required(requiredMessage),
    checkAgency: yup.boolean().oneOf(
      [true],
      intl.formatMessage({
        id: "app.modal.complete.must-agree",
        defaultMessage: "You must agree with the point above",
      }),
    ),
    checkMoberries: yup.boolean().oneOf(
      [true],
      intl.formatMessage({
        id: "app.modal.complete.must-agree",
        defaultMessage: "You must agree with the point above",
      }),
    ),
  })

  const formik = useFormik({
    initialValues: {
      firstName: me.firstName,
      lastName: me.lastName,
      email: isFromAffiliateInvite ? me.email : "",
      location: me.location,
      password1: "",
      password2: "",
      checkAgency: !isRefferedCandidate(me.tracking),
      checkMoberries: false,
    },
    onSubmit: values => {
      acceptInviteMutation.mutate({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        city: {
          placeId: values.location.placeId,
        },
        password: values.password1,
        retypePassword: values.password2,
        jobId: data.jobId,
        affiliateId: me.tracking?.manager?.id,
        candidateId: me.id,
        isTermsAgreed: values.checkAgency && values.checkMoberries,
      })
    },
    validationSchema: acceptInviteSchema,
  })
  return (
    <Fragment>
      <StepProgress steps={stepsConfig} />
      <Row className="mb-2">
        <Col>
          <LabelWithHint
            label={
              <span className="h5">
                <FormattedMessage
                  id="app.profile.completeAccount.label"
                  defaultMessage="Complete your Account"
                />
              </span>
            }
            hint={false}
          />
        </Col>
      </Row>

      <form onSubmit={formik.handleSubmit}>
        <CompleteAccountFields formik={formik} me={me} />
        <Row className="flex justify-content-center mt-5">
          <Button
            color="secondary"
            type="button"
            className="py-3 px-6 mr-2 modal_action_btn-back"
            onClick={() =>
              setCurrentStep(s => ({ from: s.to, to: StepsEnum.cv }))
            }
          >
            <FormattedMessage id="app.common.back" defaultMessage="Back" />
          </Button>

          <Button
            color="primary"
            type="submit"
            className="py-3 px-6"
            disabled={acceptInviteMutation.isLoading}
          >
            <FormattedMessage
              id="app.profile.completeAccount.btn.submit"
              defaultMessage="Submit the application"
            />
          </Button>
        </Row>
      </form>
    </Fragment>
  )
}
