// All the global styles should be imported between styles_start and styles_end
// They will be deleted during build
import "../styles/shared/essentials.scss"
import "@fortawesome/fontawesome-free/css/all.min.css"
import React from "react"
import { IntlProvider } from "react-intl"
import App, { AppContext, AppProps } from "next/app"
import NProgress from "nprogress"
import { Router } from "next/router"
import { BoardContext } from "../lib/board-context"
import { getI18nMessages, getLocale } from "../lib/helpers"
import { Board } from "../lib/types/boards"
import { SharedMeta } from "../components/shared/shared-meta"
import { getBoard } from "../lib/helpers"
import { Locales } from "../lib/types/shared"
import { Error } from "../components/shared/error"
import { CompanyGroupContext } from "../lib/company-group-context"
import dynamic from "next/dynamic"
import { QueryClient, QueryClientProvider } from "react-query"
import { TrackingLabelProvider } from "../lib/tracking-label-context"

const BrowserUpdate = dynamic(
  () => import("../components/shared/browser-update"),
  { ssr: false },
)

Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeError", () => NProgress.done())

const queryClient = new QueryClient()

const CustomApp = ({
  Component,
  pageProps,
  board,
  i18nMessages,
  locale,
}: AppProps & {
  board: Board
  i18nMessages: { [key: string]: string }
  locale: Locales
}) => {
  if (pageProps.errorCode) {
    return (
      <IntlProvider locale={locale} messages={i18nMessages}>
        <Error errorCode={pageProps.errorCode} />
      </IntlProvider>
    )
  }
  return (
    <BoardContext.Provider value={board}>
      <BrowserUpdate />
      <CompanyGroupContext.Provider value={pageProps.companyGroup}>
        <IntlProvider locale={locale} messages={i18nMessages}>
          <QueryClientProvider client={queryClient}>
            <TrackingLabelProvider>
              <SharedMeta />
              <Component {...pageProps} />
            </TrackingLabelProvider>
          </QueryClientProvider>
        </IntlProvider>
      </CompanyGroupContext.Provider>
    </BoardContext.Provider>
  )
}

CustomApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const locale = getLocale(appContext.ctx)

  return {
    ...appProps,
    board: getBoard(appContext.ctx.req),
    locale,
    i18nMessages: await getI18nMessages(locale),
  }
}

export default CustomApp

