import React, { useContext } from "react"
import Link from "next/link"
import { Job } from "../../lib/types/moberries-entities"
import { sanitizeForUrl } from "../../lib/helpers"
import { TrackingLabelContext } from "../../lib/tracking-label-context"

export const JobLink: React.FC<{ job: Job }> = ({ job, children }) => {
  const { trackingLabel } = useContext(TrackingLabelContext)

  const companyName = sanitizeForUrl(job.company.name)
  const jobTitle = sanitizeForUrl(job.title)
  const jobId = job.id
  let params = ""

  if (trackingLabel) {
    params += `?utm_content=${trackingLabel}`
  }

  return (
    <Link href={`/job/${jobId}_${jobTitle}_${companyName}${params}`}>
      {children}
    </Link>
  )
}
