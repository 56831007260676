import React, { Fragment, useState } from "react"
import { UploadCvModal } from "./upload-cv-modal/upload-cv-modal"
import { Modal } from "../../shared/modal-dialog/modal-dialog"
import { CompleteAccountModal } from "./details-modal/complete-account-modal"
import { SuccessModal } from "./success-modal/success-modal"
import { useQuery } from "react-query"
import { moberriesApi } from "../../../lib/moberries-api"
import { DiscardModal } from "./discard-modal/discard-modal"
import { ConfirmationModal } from "./confirmation-modal/confirmation-modal"
import { ErrorModal } from "./error-modal/error-modal"

export interface EasyApplyDataInterface {
  candidateId: Nullable<number>
  managerId: Nullable<number>
  token: Nullable<string>
  jobId: Nullable<number>
  tracking: Nullable<{
    utmSource: Nullable<string>
    utmContent: Nullable<string>
  }>
}

export interface EasyApplyProps {
  onClose: () => void
  isOpen: boolean
  data: EasyApplyDataInterface
}

export enum StepsEnum {
  error = "error",
  cv = "cv",
  details = "details",
  success = "success",
  discard = "discard",
  confirm = "confirm",
}

export interface StepsInterface {
  from: StepsEnum
  to: StepsEnum
}

const DefaultEasyApplyModal: React.FC<EasyApplyProps> = ({
  onClose,
  isOpen,
  data,
}) => {
  const [candidateHasCV, setCandidateHasCV] = useState(false)
  const initialStep = candidateHasCV ? StepsEnum.details : StepsEnum.cv
  const [currentStep, setCurrentStep] = useState<StepsInterface>({
    from: initialStep,
    to: initialStep,
  })

  const { data: me, isLoading } = useQuery(
    "me",
    async () => {
      const res = await moberriesApi.getMe({
        Authorization: `JWT ${data.token}`,
      })
      return res.data
    },
    {
      enabled: !!data.token,
      retry: false,
      onSuccess: data => {
        setCandidateHasCV(!!data.candidateCv?.isUploaded)
      },
      onError: () => {
        if (currentStep.to !== StepsEnum.success) {
          setCurrentStep({
            from: StepsEnum.error,
            to: StepsEnum.error,
          })
        }
      },
    },
  )

  if (isLoading) {
    return null
  }

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          if ([StepsEnum.cv, StepsEnum.details].includes(currentStep.to)) {
            setCurrentStep({ from: currentStep.to, to: StepsEnum.discard })
          } else {
            onClose()
            // For modal animation. When no timeout, state changes faster than modal is being closed
            setTimeout(() => {
              setCurrentStep(() => ({ from: initialStep, to: initialStep }))
            }, 300)
          }
        }}
        size="lg"
        hideClose={currentStep.to === StepsEnum.discard}
      >
        <div
          className="easy-apply"
          style={{ maxWidth: "500px", margin: "auto" }}
        >
          {currentStep.to === StepsEnum.error && <ErrorModal />}
          {currentStep.to === StepsEnum.cv && (
            <UploadCvModal
              setCurrentStep={setCurrentStep}
              data={data}
              candidateHasCV={candidateHasCV}
              uploadedCv={me?.candidateCv?.urls?.original}
            />
          )}
          {currentStep.to === StepsEnum.confirm && (
            <ConfirmationModal setCurrentStep={setCurrentStep} />
          )}
          {currentStep.to === StepsEnum.details && (
            <CompleteAccountModal
              setCurrentStep={setCurrentStep}
              data={data}
              me={me}
            />
          )}
          {currentStep.to === StepsEnum.success && (
            <SuccessModal onClose={onClose} />
          )}
          {currentStep.to === StepsEnum.discard && (
            <DiscardModal
              onDiscard={() => {
                onClose()
                setTimeout(() => {
                  setCurrentStep(() => ({ from: initialStep, to: initialStep }))
                }, 300)
              }}
              onBack={() =>
                setCurrentStep(s => ({
                  from: StepsEnum.discard,
                  to: [StepsEnum.cv, StepsEnum.details].includes(s.from)
                    ? s.from
                    : initialStep,
                }))
              }
            />
          )}
        </div>
      </Modal>
    </Fragment>
  )
}

export default DefaultEasyApplyModal
