import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { CareerLevels } from "../../lib/types/moberries-entities"

export const CareerLevel: React.FC<{ level: CareerLevels }> = ({ level }) => (
  <Fragment>
    {
      {
        [CareerLevels.Student]: (
          <FormattedMessage
            id="shared.careerLevel.student"
            defaultMessage="Student"
          />
        ),
        [CareerLevels.Entry]: (
          <FormattedMessage
            id="shared.careerLevel.entryLevel"
            defaultMessage="Entry level"
          />
        ),
        [CareerLevels.Intermediate]: (
          <FormattedMessage
            id="shared.careerLevel.intermediate"
            defaultMessage="Intermediate"
          />
        ),
        [CareerLevels.Senior]: (
          <FormattedMessage
            id="shared.careerLevel.senior"
            defaultMessage="Senior"
          />
        ),
        [CareerLevels.Lead]: (
          <FormattedMessage
            id="shared.careerLevel.lead"
            defaultMessage="Lead / Head"
          />
        ),
        [CareerLevels.Executive]: (
          <FormattedMessage
            id="shared.careerLevel.executive"
            defaultMessage="Executive"
          />
        ),
      }[level]
    }
  </Fragment>
)
