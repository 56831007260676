import { defaultTo, head, isNil, unless } from "ramda"
import { config } from "../config"
import { City } from "../types/moberries-entities"

export function randomInteger(min: number, max: number) {
  return Math.round(min - 0.5 + Math.random() * (max - min + 1))
}

export function sample<T>(array: T[]) {
  if (!array || array.length === 0) {
    return null
  }
  if (array.length === 1) {
    return head<T>(array)
  }

  return array[randomInteger(0, array.length - 1)]
}

export function popupCenter(
  url: string,
  title: string,
  w: number,
  h: number,
): void {
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width

  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height

  const left = width / 2 - w / 2 + window.screenLeft
  const top = height / 2 - h / 2 + window.screenTop
  const newWindow = window.open(
    url,
    title,
    "scrollbars=yes, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left,
  )

  if (newWindow?.focus) {
    newWindow.focus()
  }
}

export function getLocationName(location: City) {
  let locationName = location.name

  if (location.country?.name) {
    locationName += `, ${location.country.name}`
  }

  return locationName
}

export function stopPropagation(fn: any) {
  return (e: any) => {
    e.stopPropagation()
    return fn(e)
  }
}

export function getBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onabort = error => {
      reject(error)
    }

    reader.onerror = error => {
      reject(error)
    }
  })
}

export const setJobliftStartTracker = () => {
  const setTracker = (j: any, o: any, b: string) => {
    j.jlq = [["init", { cid: "557", tid_param: "jl_tid" }]] // eslint-disable-line
    const l = o.createElement("script")
    l.async = true
    l.src = b
    const i = o.getElementsByTagName("head")[0]
    i.appendChild(l)
  }
  setTracker(window, document, "https://assets.joblift.com/tr/de.js")
}

export const setJobliftIntentTracker = () => {
  const setTracker = (j: any, o: any, b: string) => {
    j.jlq = [
      ["init", { cid: "557", tid_param: "jl_tid", inital_page_view: false }], // eslint-disable-line
    ]
    const l = o.createElement("script")
    l.async = true
    l.src = b
    const i = o.getElementsByTagName("head")[0]
    i.appendChild(l)
  }
  setTracker(window, document, "https://assets.joblift.com/tr/de.js")
  window.jlq.push(["application_intent"])
}

export const getApplyUrl = ({
  source,
  jobId,
  companyGroupId,
  trackerId,
  clickId,
}: {
  [key: string]: string | string[] | number | undefined
}) => {
  const applyParams = []

  let applyUrl = `${config.urls.candidateApp}/apply/${jobId}`

  if (companyGroupId) {
    applyParams.push(`ref=g-${companyGroupId}`)
  }

  if (source === "joblift") {
    applyParams.push(`jl_tid=${trackerId}`)
  }

  if (source === "jobg8") {
    applyParams.push(`jg_clickid=${clickId}`)
  }

  if (applyParams.length) {
    applyUrl += `?${applyParams.join("&")}`
  }

  return applyUrl
}

export const safeTransformToNumber = (value: any) =>
  defaultTo(null)(unless(isNil, Number)(value))

export const safeTransformToString = (value: any) =>
  defaultTo(null)(unless(isNil, String)(value))

export const isRefferedCandidate = (tracking: any) =>
  Boolean(tracking?.manager || tracking?.company)

export const getDefaultCitiesList = () => [
  { placeId: "ChIJAVkDPzdOqEcRcDteW0YgIQQ", name: "Berlin" },
  {
    placeId: "ChIJ2V-Mo_l1nkcRfZixfUq4DAE",
    name: "München",
  },
  {
    placeId: "ChIJuRMYfoNhsUcRoDrWe_I9JgQ",
    name: "Hamburg",
  },
  {
    placeId: "ChIJxZZwR28JvUcRAMawKVBDIgQ",
    name: "Frankfurt",
  },
  {
    placeId: "ChIJ04-twTTbmUcR5M-RdxzB1Xk",
    name: "Stuttgart",
  },
  { placeId: "ChIJ5S-raZElv0cR8HcqSvxgJwQ", name: "Köln" },
  {
    placeId: "ChIJCXjgokgGl0cRf-63THNV_LY",
    name: "Karlsruhe",
  },
  {
    placeId: "ChIJB1lG8XvJuEcRsHMqSvxgJwQ",
    name: "Düsseldorf",
  },
  { placeId: "ChIJdd4hrwug2EcRmSrV3Vo6llI", name: "London" },
  { placeId: "ChIJD7fiBh9u5kcRYJSMaMOCCwQ", name: "Paris" },
  {
    placeId: "ChIJVXealLU_xkcRja_At0z9AGY",
    name: "Amsterdam",
  },
  {
    placeId: "ChIJ93UDf0JFDhMRr6WCLuLCOHo",
    name: "St Julian's, Malta",
  },
]

export const getRemoteRegionsOptionsList = () => [
  {
    value: "all",
    label: "Worldwide",
  },
  {
    label: "Only from",
    options: [
      {
        value: "western_europe",
        label: "Western Europe",
      },
      {
        value: "eastern_europe",
        label: "Eastern Europe",
      },
      {
        value: "south_america",
        label: "South America",
      },
      {
        value: "north_america",
        label: "North America",
      },
      {
        value: "oceania",
        label: "Oceania",
      },
      {
        value: "asia",
        label: "Asia",
      },
      {
        value: "middle_east",
        label: "Middle East",
      },
      {
        value: "africa",
        label: "Africa",
      },
    ],
  },
]
