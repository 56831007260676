import DefaultComponents from "../default/default-components"

const RedstoneComponents = {
  ...DefaultComponents,
  Header: () => null,
  Footer: () => null,
  Hero: () => null,
}

export default RedstoneComponents
