import React, { Fragment, useState } from "react"
import { Button, Col, Modal, ModalHeader, PopoverBody, Row } from "reactstrap"
import { FormattedMessage } from "react-intl"
import cn from "classnames"
import { FileRejection, useDropzone } from "react-dropzone"
import { LabelWithHint } from "../../../shared/label-with-hint"
import { InfoHint } from "../../../shared/info-hint"
import { Spinner } from "../../../shared/spinner"
import { getBase64, stopPropagation } from "../../../../lib/helpers"
import { useOpenClose } from "../../../../hooks/use-open-close"
import { useMutation } from "react-query"
import {
  EasyApplyDataInterface,
  StepsEnum,
  StepsInterface,
} from "../default-easy-apply-modal"
import { moberriesApi } from "../../../../lib/moberries-api"
import { StepProgress } from "../steps-progress/step-progress"

const cvConfig = {
  limit: 5242880,
  acceptedTypes:
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}

const enErrors = {
  "app.profile.cv.errors.fileType": "File type must be PDF or Word",
  "app.profile.cv.errors.size": "File is too large",
  "app.profile.cv.errors.server":
    "Please, make sure you you uploaded a correct file, or try again",
}

const stepsConfig = [
  {
    label: "1",
    active: true,
    passed: false,
  },
  {
    label: "2",
    active: false,
    passed: false,
  },
]

interface CompleteAccountModalProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<StepsInterface>>
  data: EasyApplyDataInterface
  candidateHasCV: boolean
  uploadedCv?: string
}

export const UploadCvModal: React.FC<CompleteAccountModalProps> = ({
  setCurrentStep,
  data,
  candidateHasCV,
  uploadedCv,
}) => {
  const [isUploaded, setIsUploaded] = useState(candidateHasCV)
  const [error, setError] = useState<string>("")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingPrediction, setIsLoadingPrediction] = useState(false)

  const {
    value: previewIsOpen,
    open: openPreview,
    close: closePreview,
  } = useOpenClose({ initialValue: false })

  const handleUpload = async ({
    file,
    reject,
  }: {
    file: File
    reject: FileRejection
  }) => {
    if (!file && reject) {
      const [err] = reject.errors

      if (err.code === "file-too-large") {
        throw new Error("app.profile.cv.errors.size")
      }

      if (err.code === "file-invalid-type") {
        throw new Error("app.profile.cv.errors.fileType")
      }

      throw new Error(err.message)
    }

    const base64Cv = await getBase64(file)

    if (!base64Cv) {
      throw new Error("Invalid or broken file")
    }

    try {
      await moberriesApi.uploadCandidateCv(
        { data: base64Cv },
        {
          Authorization: `JWT ${data.token}`,
        },
      )
      return base64Cv
    } catch (e) {
      throw new Error(
        "Please, make sure you you uploaded a correct file, or try again",
      )
    }
  }

  const [cv, setCv] = useState<any>(uploadedCv)

  const uploadMutation = useMutation(handleUpload, {
    onSuccess: cvBase64 => {
      setIsUploaded(true)
      setCv(cvBase64)

      // TODO: Also load predictions
    },
    onError: (e: any) => {
      setError(e.message)
    },
  })

  const isUploading = uploadMutation.isLoading

  const noUploadStarted = !isUploaded && !isUploading && !isLoadingPrediction
  const isUploadedWithoutPrediction =
    isUploaded && !isUploading && !isLoadingPrediction

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: cvConfig.acceptedTypes,
    onDrop: ([file], [reject]) => {
      setError("")
      uploadMutation.mutate({ file, reject })
    },
    disabled: isUploading,
    multiple: false,
    maxSize: cvConfig.limit,
  })

  return (
    <Fragment>
      <Modal
        contentClassName="cv-preview-modal"
        isOpen={previewIsOpen && cv}
        toggle={closePreview}
        size="lg"
        centered
      >
        <ModalHeader toggle={closePreview} className="text-white px-0">
          <FormattedMessage id="app.sd" defaultMessage="CV preview" />
        </ModalHeader>
        <object data={cv} style={{ width: "100%", height: "80vh" }}>
          CV
        </object>
      </Modal>
      <StepProgress steps={stepsConfig} />
      <div className="d-flex justify-content-between align-items-baseline">
        <LabelWithHint
          label={
            <span className="h5">
              <FormattedMessage
                id="app.profile.cv.label"
                defaultMessage="Upload your CV file"
              />
            </span>
          }
          hint={
            <InfoHint
              id="cvHint"
              popover={
                <PopoverBody>
                  <FormattedMessage
                    id="app.profile.cv.hint"
                    defaultMessage="Upload the most recent version of your CV her. You can always update it in the future. We support PDF and Word files with the max size of 5MB."
                  />
                </PopoverBody>
              }
            />
          }
          isRequired
          for="cvHint"
        />
        <button
          className="mb-2"
          style={{
            height: 21,
            border: "none",
            color: "#0738da",
            background: "none",
            outline: "none",
          }}
          onClick={() => {
            setCurrentStep(s => ({ from: s.to, to: StepsEnum.confirm }))
          }}
        >
          <FormattedMessage
            id="app.profile.cv.noCv.button"
            defaultMessage="Proceed without CV"
          />
        </button>
      </div>

      <div
        className={cn(
          "text-center py-4  align-items-center border d-flex flex-column border-dashed border-wider rounded no-outline",
          {
            "border-success":
              isUploaded || isUploading || isLoadingPrediction || isDragActive,
            "border-secondary-light": noUploadStarted && !isDragActive,
            "border-danger": error,
          },
        )}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Col xs="12" md="10">
          <Spinner
            isSpinning={isUploading || isLoadingPrediction}
            isComplete={isUploadedWithoutPrediction}
            className="mb-3 d-flex justify-content-center align-items-center"
          >
            <i
              className={cn({
                "far fa-file-alt fa-3x text-success": isUploading,
                "fas fa-cogs fa-3x text-success": isLoadingPrediction,
                "fas fa-check fa-3x  text-success": isUploadedWithoutPrediction,
                "far fa-file-alt fa-3x text-info": noUploadStarted,
                "far fa-file-alt fa-3x text-danger": error,
              })}
            />
          </Spinner>

          {isUploaded && !isLoadingPrediction && !error && (
            <Fragment>
              <Col xs="12" md={{ size: 8, offset: 2 }}>
                <FormattedMessage
                  id="app.profile.cv.uploaded"
                  defaultMessage="Your CV has been successfully uploaded. You can preview it or re-upload a new one."
                />
              </Col>
              <Row className="justify-content-center">
                <Col
                  xs="12"
                  md="5"
                  className="d-md-flex justify-content-end px-0"
                >
                  <Button
                    color="link"
                    className="p-0"
                    disabled={isUploading || isLoadingPrediction}
                    onClick={stopPropagation(openPreview)}
                  >
                    <FormattedMessage
                      id="app.profile.cv.preview.header"
                      defaultMessage="Preview your CV"
                    />
                  </Button>
                </Col>
                <Col md="1" className="px-0">
                  |
                </Col>
                <Col
                  xs="12"
                  md="5"
                  className="d-md-flex align-items-start px-0"
                >
                  <Button
                    color="link"
                    disabled={isUploading || isLoadingPrediction}
                    className="p-0"
                  >
                    <FormattedMessage
                      id="app.profile.cv.button.update"
                      defaultMessage="Re-upload a new one"
                    />
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}

          {!isUploaded && !isLoadingPrediction && !error && (
            <Fragment>
              <Col xs="12" md={{ size: 12, offset: 0 }}>
                <FormattedMessage
                  id="app.profile.cv.dragOrBrowse"
                  values={{
                    browse: (
                      <span className="p-0 btn-link clickable">
                        <FormattedMessage
                          id="app.common.browse"
                          defaultMessage="click here"
                        />
                      </span>
                    ),
                  }}
                  defaultMessage="In order to upload your CV {browse} to select it or drag and drop it here."
                />
              </Col>
              <div className="text-muted mt-2">
                <FormattedMessage
                  id="app.profile.cv.maxSize"
                  defaultMessage="Maximum size is 5MB"
                />
              </div>
            </Fragment>
          )}

          {error && (
            <Col xs="12" md={{ size: 12, offset: 0 }}>
              <div className="text-danger">
                <FormattedMessage
                  id={error}
                  defaultMessage={enErrors[error as keyof typeof enErrors]}
                />
              </div>
            </Col>
          )}

          {isLoadingPrediction && (
            <div className="px-3">
              <FormattedMessage
                id="app.registration.cvIsAnalyzed"
                defaultMessage="Please be patient. We are analysing your file in order to predict useful data"
              />
            </div>
          )}
        </Col>
      </div>
      <Row className="flex justify-content-center mt-5">
        <Button
          color="primary"
          className="py-3 px-6"
          onClick={() =>
            setCurrentStep(s => ({ from: s.to, to: StepsEnum.details }))
          }
          disabled={!isUploaded}
        >
          <FormattedMessage id="app.common.next" defaultMessage="Next" />
        </Button>
      </Row>
    </Fragment>
  )
}
