import { useRouter } from "next/router"

export const useIsFromAffiliateInvite = () => {
  const router = useRouter()
  const {
    candidate_id: candidateId,
    manager_id: managerId,
    token,
  } = router.query

  return Boolean(candidateId && managerId && token)
}
