import { NextPageContext } from "next"
import { Locales } from "../types/shared"
import { getBoard, getBoardDefaultLocale } from "./board"
import nookies from "nookies"

const getLocaleFromPreferredLanguages = (ctx: NextPageContext): Locales => {
  const preferredLanguages = ctx.req?.headers?.["accept-language"]?.split(",")
  const preferredLanguage = preferredLanguages?.[0]
  if (preferredLanguage?.toLocaleLowerCase().startsWith(Locales.DE)) {
    return Locales.DE
  }
  return Locales.EN
}

export function getLocale(ctx: NextPageContext): Locales {
  const board = getBoard(ctx.req)
  const defaultLocale = getBoardDefaultLocale(board)

  const cookies = nookies.get(ctx)
  return (cookies.locale ||
    getLocaleFromPreferredLanguages(ctx) ||
    defaultLocale) as Locales
}

export async function getI18nMessages(locale: Locales) {
  let i18nMessages = null
  if (locale === Locales.DE) {
    const module = (i18nMessages = await import(`../../i18n/${locale}.json`))
    i18nMessages = module.default
  }

  return i18nMessages
}

export function switchLocale(currentLocale: Locales) {
  const YEAR_IN_SECONDS = 60 * 60 * 24 * 365
  const nextLocale = currentLocale === Locales.EN ? Locales.DE : Locales.EN
  nookies.set(null, "locale", nextLocale, {
    maxAge: YEAR_IN_SECONDS,
    path: "/",
  })
  window.location.reload()
}
