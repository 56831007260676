import React from "react"
import cn from "classnames"

/*
    TODO: Fix not working styles:
        - circle with complete is not full
 */

export const Spinner: React.FC<{
  isSpinning: boolean
  isComplete: boolean
  className: string
}> = ({ isSpinning, isComplete, children, className }) => {
  return (
    <div
      className={cn("moberries-spinner", className, {
        complete: isComplete,
        spinning: isSpinning,
      })}
    >
      {children}
    </div>
  )
}
