import { useRouter } from "next/router"
import { ParsedUrlQuery } from "querystring"
import { useContext, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Button } from "reactstrap"
import { useComponents } from "../../hooks/use-components"
import { useIsFromAffiliateInvite } from "../../hooks/use-is-from-affiliate-invite"
import { useOpenClose } from "../../hooks/use-open-close"
import { CompanyGroupContext } from "../../lib/company-group-context"
import { safeTransformToNumber, safeTransformToString } from "../../lib/helpers"
import { moberriesApi } from "../../lib/moberries-api"
import { EasyApplyDataInterface } from "./default-easy-apply-modal/default-easy-apply-modal"

const extractJobIdFromUrlId = (urlId: string | string[] = "") =>
  String(urlId)?.split("_")[0] || null

const routerQueryToApplicationData = ({
  candidate_id: candidateId,
  manager_id: managerId,
  id: jobId,
  utm_source: utmSource,
  utm_content: utmContent,
  token,
}: ParsedUrlQuery) => ({
  candidateId: safeTransformToNumber(candidateId),
  managerId: safeTransformToNumber(managerId),
  token: safeTransformToString(token),
  jobId: safeTransformToNumber(extractJobIdFromUrlId(jobId)),
  tracking: {
    utmSource: safeTransformToString(utmSource),
    utmContent: safeTransformToString(utmContent),
  },
})

const DefaultEasyApplyButton: React.FC = () => {
  const {
    value: isModalOpened,
    open: openModal,
    close: closeModal,
  } = useOpenClose({ initialValue: false })

  const { EasyApplyModal } = useComponents()
  const router = useRouter()
  const isFromAffiliateInvite = useIsFromAffiliateInvite()
  const companyGroup = useContext(CompanyGroupContext)

  const [applicationData, setApplicationData] = useState<
    EasyApplyDataInterface
  >(routerQueryToApplicationData(router.query))

  const preCreateCandidate = async (
    job: number,
    manager: Nullable<number>,
    tracking: Nullable<{ [key: string]: string | null }>,
  ) => {
    const { data } = await moberriesApi.createCandidateFromSharedLink({
      data: {
        referral: companyGroup ? `g-${companyGroup.id}` : null,
        job,
        manager,
        tracking,
      },
    })

    return data
  }

  const clickHandler = async () => {
    const { jobId: job, managerId: manager, tracking } = applicationData

    if (!job) {
      return
    }

    if (!isFromAffiliateInvite) {
      const { id, token } = await preCreateCandidate(job, manager, tracking)
      setApplicationData({
        ...applicationData,
        candidateId: id,
        token,
      })
      openModal()
    } else {
      openModal()
    }
  }

  return (
    <>
      <EasyApplyModal
        onClose={closeModal}
        isOpen={isModalOpened}
        data={applicationData}
      />
      <Button block color="primary" onClick={clickHandler}>
        <FormattedMessage
          id="default.jobSidebar.easyApply"
          defaultMessage="Easy apply"
        />
      </Button>
    </>
  )
}

export default DefaultEasyApplyButton
