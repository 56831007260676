export const config = {
  env: {
    isProduction: process.env.NODE_ENV === "production",
    travisBranch: process.env.TRAVIS_BRANCH,
  },
  package: {
    name: process.env.NEXT_PUBLIC_PACKAGE_NAME,
    version: process.env.NEXT_PUBLIC_PACKAGE_VERSION,
    buildYear: process.env.NEXT_PUBLIC_BUILD_YEAR,
  },
  urls: {
    companyApp: process.env.NEXT_PUBLIC_MOBERRIES_COMPANY_APP_URL,
    candidateApp: process.env.NEXT_PUBLIC_MOBERRIES_CANDIDATE_APP_URL,
    homepage: process.env.NEXT_PUBLIC_MOBERRIES_HOMEPAGE_URL,
    help: process.env.NEXT_PUBLIC_MOBERRIES_HELP_URL,
    api:
      process.env.MOBERRIES_API_CLUSTER_URL ||
      process.env.NEXT_PUBLIC_MOBERRIES_API_URL,
  },
  facebook: {
    appId: process.env.NEXT_PUBLIC_FACEBOOK_ID,
  },
  google: {
    api: {
      places: {
        key: "AIzaSyDeZ5L1C1WEpUgsvQbibYEqAHJ7d3NHbwM",
      },
    },
  },
}
