import React from "react"
import { Job, JobStatuses } from "../../lib/types/moberries-entities"
import { useComponents } from "../../hooks/use-components"
import { useIsFromAffiliateSharedLink } from "../../hooks/use-is-from-affiliate-shared-link"
import { useIsFromAffiliateInvite } from "../../hooks/use-is-from-affiliate-invite"

interface DefaultJobSidebar {
  job: Job
  similarJobs: Job[]
}

declare global {
  interface Window {
    jlq: any
  }
}

const DefaultJobSidebar: React.FC<DefaultJobSidebar> = ({
  job,
  similarJobs,
}) => {
  const { SimilarJobs, JobShare, ApplyButton } = useComponents()

  const isFromAffiliateInvitation = useIsFromAffiliateInvite()
  const isFromAffiliateSharedLink = useIsFromAffiliateSharedLink()
  const isFromAffiliate = isFromAffiliateSharedLink || isFromAffiliateInvitation

  return (
    <div>
      {job.status === JobStatuses.ACT && (
        <>
          <div className="mb-2 d-none d-lg-block">
            <ApplyButton />
          </div>
          <JobShare job={job} />
        </>
      )}
      {similarJobs.length > 0 && !isFromAffiliate && (
        <SimilarJobs jobs={similarJobs} />
      )}
    </div>
  )
}

export default DefaultJobSidebar
