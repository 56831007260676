import React from "react"
import { Col, Row, FormGroup, Label, Input } from "reactstrap"
import AsyncSelect from "react-select/async"
import { FormattedMessage } from "react-intl"
import { FormikContextType } from "formik"
import { useIsFromAffiliateInvite } from "../../../../hooks/use-is-from-affiliate-invite"
import { getSelectStyles } from "../../../../lib/helpers/style"
import { googleMapsApi } from "../../../../lib/google-maps-api"
import { MenuListPoweredByGoogle } from "./menu-list-powered-by-google"
import { getLocationName, isRefferedCandidate } from "../../../../lib/helpers"

export const FormattedErrorMessage = ({ error }: any) => {
  if (!error) {
    return null
  }
  if (typeof error === "string") {
    return (
      <div className="text-danger">
        <FormattedMessage id={error} defaultMessage={error} />
      </div>
    )
  } else {
    if (!error.id) {
      return null
    }
    if (typeof error.id !== "string") {
      return (
        <div className="text-danger">
          <FormattedMessage {...error.id} defaultMessage={error.id} />
        </div>
      )
    } else {
      return (
        <div className="text-danger">
          <FormattedMessage {...error} defaultMessage={error.id} />
        </div>
      )
    }
  }
}

interface CompleteAccountFieldsProps {
  formik: FormikContextType<any>
  me: any
}

export const CompleteAccountFields: React.FC<CompleteAccountFieldsProps> = ({
  formik,
  me,
}) => {
  const isFromAffiliateInvite = useIsFromAffiliateInvite()

  return (
    <>
      <Row>
        <Col xs="12" md="6" className="input__element">
          <label>
            <FormattedMessage
              id="app.profile.completeAccount.input.email"
              defaultMessage="Email"
            />
            <Input
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              disabled={isFromAffiliateInvite}
            />
          </label>
          {formik.touched.email && (
            <FormattedErrorMessage error={{ id: formik.errors.email }} />
          )}
        </Col>
        <Col xs="12" md="6" className="select__element">
          <label>
            <FormattedMessage
              id="app.profile.completeAccount.input.location"
              defaultMessage="Location"
            />
            <AsyncSelect
              inputId="location"
              components={{ MenuList: MenuListPoweredByGoogle }}
              touced={formik.touched.location}
              onBlur={formik.handleBlur}
              onChange={option => formik.setFieldValue("location", option)}
              value={formik.values.location}
              getOptionValue={option => option.placeId}
              getOptionLabel={getLocationName}
              loadOptions={googleMapsApi.getCities}
              styles={getSelectStyles()}
              noOptionsMessage={(): any => (
                <FormattedMessage
                  id="app.common.type.to.search"
                  defaultMessage="Type to search"
                />
              )}
              isClearable
            />
          </label>
          <Input
            type="hidden"
            invalid={formik.touched.location && Boolean(formik.errors.location)}
          />
          {formik.touched.location && (
            <FormattedErrorMessage error={{ id: formik.errors.location }} />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="6" className="input__element">
          <Label>
            <FormattedMessage
              id="app.profile.completeAccount.input.firstName"
              defaultMessage="First Name"
            />
            <Input
              name="firstName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
          </Label>
          {formik.touched.firstName && (
            <FormattedErrorMessage error={{ id: formik.errors.firstName }} />
          )}
        </Col>
        <Col xs="12" md="6" className="input__element">
          <Label>
            <FormattedMessage
              id="app.profile.completeAccount.input.lastName"
              defaultMessage="Last Name"
            />
            <Input
              name="lastName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
          </Label>
          {formik.touched.lastName && (
            <FormattedErrorMessage error={{ id: formik.errors.lastName }} />
          )}
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="6" className="input__element">
          <Label>
            <FormattedMessage
              id="app.profile.completeAccount.input.password"
              defaultMessage="Enter your password"
            />
            <Input
              name="password1"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password1}
            />
          </Label>
          {formik.touched.password1 && (
            <FormattedErrorMessage error={{ id: formik.errors.password1 }} />
          )}
        </Col>
        <Col xs="12" md="6" className="input__element">
          <Label>
            <FormattedMessage
              id="app.profile.completeAccount.input.retypePassword"
              defaultMessage="Retype your password"
            />
            <Input
              name="password2"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password2}
            />
          </Label>
          {formik.touched.password2 && (
            <FormattedErrorMessage error={{ id: formik.errors.password2 }} />
          )}
        </Col>
      </Row>

      <Row className="mt-2 text-muted">
        {isRefferedCandidate(me.tracking) && (
          <FormGroup check>
            <Col xs="12" md="12">
              <Label check>
                <Input
                  type="checkbox"
                  name="checkAgency"
                  className="checkbox__input"
                  onChange={formik.handleChange}
                  checked={formik.values.checkAgency}
                />{" "}
                <FormattedMessage
                  id="app.profile.completeAccount.input.checkAgency"
                  defaultMessage="You agree that the inviting agency can have an access to your data on MoBerries. This includes your profile as well as the information about your application processes."
                />
              </Label>
              {formik.touched.checkAgency && (
                <FormattedErrorMessage
                  error={{ id: formik.errors.checkAgency }}
                />
              )}
            </Col>
          </FormGroup>
        )}

        <FormGroup check>
          <Col xs="12" md="12" className="mt-2">
            <Label check>
              <Input
                type="checkbox"
                name="checkMoberries"
                className="checkbox__input"
                onChange={formik.handleChange}
                checked={formik.values.checkMoberries}
              />{" "}
              <FormattedMessage
                id="app.profile.completeAccount.input.checkMoberries"
                values={{
                  terms: (
                    <a
                      href="https://www.moberries.com/terms/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage
                        id="app.common.terms"
                        defaultMessage="Terms & Conditions"
                      />
                    </a>
                  ),
                  privacy: (
                    <a
                      href="https://www.moberries.com/privacy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FormattedMessage
                        id="app.common.privacy"
                        defaultMessage="Privacy Policy"
                      />
                    </a>
                  ),
                }}
                defaultMessage="By signing up, you agree to the {terms} and {privacy}, including Cookie Use."
              />
            </Label>
            {formik.touched.checkMoberries && (
              <FormattedErrorMessage
                error={{ id: formik.errors.checkMoberries }}
              />
            )}
          </Col>
        </FormGroup>
      </Row>
    </>
  )
}
