import React from "react"
import Link from "next/link"
import { Container, Row, Col } from "reactstrap"
import { Company } from "../../lib/types/moberries-entities"
import { useComponents } from "../../hooks/use-components"
import { FormattedMessage } from "react-intl"

interface DefaultPartnersWithMostJobsProps {
  companies: Company[]
  companiesCount: number
}

const DefaultPartnersWithMostJobs: React.FC<DefaultPartnersWithMostJobsProps> = ({
  companies,
  companiesCount,
}) => {
  const { CompanyCard, PartnersWithMostJobsHeader } = useComponents()

  return (
    <Container tag="section" className="my-5 partners-with-most-jobs">
      <Row>
        <Col>
          <PartnersWithMostJobsHeader className="mb-5 partners-with-most-jobs__title" />
        </Col>
      </Row>
      <Row className="mb-4">
        {companies.map(c => (
          <Col key={c.id} md={6} lg={4} className="py-1">
            <CompanyCard company={c} />
          </Col>
        ))}
      </Row>
      {companiesCount > 6 && (
        <div className="text-center">
          <Link href="/jobs/companies">
            <a className="btn btn-primary text-uppercase rounded">
              <FormattedMessage
                id="default.partnersWithMostJobs.morePartners"
                defaultMessage="More partners"
              />
            </a>
          </Link>
        </div>
      )}
    </Container>
  )
}

export default DefaultPartnersWithMostJobs
