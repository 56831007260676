import React from "react"
import cn from "classnames"

import styles from "./modal-dialog.module.scss"

interface ModalProps {
  className?: string
  title?: string
  subTitle?: string
  size?: "md" | "lg"
  isOpen: boolean
  toggle: () => void
  hideClose?: boolean
}

export const Modal: React.FC<ModalProps> = ({
  className,
  title,
  subTitle,
  size = "md",
  isOpen,
  toggle,
  children,
  hideClose,
}) => {
  return (
    <div className={cn([styles.modal__wrap, { [styles.visible]: isOpen }])}>
      <div className={cn(styles.modal, styles[`modal_${size}`], className)}>
        <div className={styles.modal__btn_close__wrap}>
          {!hideClose && (
            <button className={styles.modal__btn_close} onClick={toggle}>
              <img src="/web-catalog-app/assets/modal-close.svg" alt="Close" />
            </button>
          )}
        </div>
        <div>
          {title && (
            <div>
              <h2>{title}</h2>
            </div>
          )}
          {subTitle && (
            <div>
              <p>subTitle</p>
            </div>
          )}
        </div>
        <div className={styles.modal__content}>{children}</div>
      </div>
    </div>
  )
}
