import { BoardLayoutTypes } from "../lib/types/boards"
import LhoftComponents from "./lhoft/lhoft-components"
import DefaultComponents from "./default/default-components"
import BestCareerComponents from "./bestcareer/bestcareer-components"
import RemoteComponents from "./remote/remote-components"
import HtgfComponents from "./htgf/htgf-components"
import RedstoneComponents from "./redstone/redstone-components"
import TalentBerlinComponents from "./telent-berlin/talent-berlin-components"

export const getComponentCollection = (layoutType: BoardLayoutTypes) => {
  return {
    [BoardLayoutTypes.Lhoft]: LhoftComponents,
    [BoardLayoutTypes.Default]: DefaultComponents,
    [BoardLayoutTypes.Remote]: RemoteComponents,
    [BoardLayoutTypes.BestCareer]: BestCareerComponents,
    [BoardLayoutTypes.Htgf]: HtgfComponents,
    [BoardLayoutTypes.Redstone]: RedstoneComponents,
    [BoardLayoutTypes.TalentBerlin]: TalentBerlinComponents,
  }[layoutType]
}
