import dynamic from "next/dynamic"
import DefaultPartnersWithMostJobs from "./default-partners-with-most-jobs"
import DefaultPartnersWithMostJobsHeader from "./default-partners-with-most-jobs-header"
import DefaultJobList from "./default-job-list"
import DefaultJobCard from "./default-job-card"
import DefaultCompanyInfo from "./default-company-info"
import DefaultCompanyCard from "./default-company-card"
import DefaultJobInfo from "./default-job-info"
import DefaultJobDetails from "./default-job-details"
import DefaultJobDescription from "./default-job-description"
import DefaultJobSidebar from "./default-job-sidebar"
import DefaultSimilarJobs from "./default-similar-jobs"
import DefaultJobShare from "./default-job-share"
import DefaultCompaniesList from "./default-companies-list"
import DefaultJobsFilter from "./default-jobs-filter"
import DefaultHero from "./default-hero"
import DefaultInactiveJob from "./default-inactive-job"

import DefaultMeta from "./default-meta"
import DefaultTracking from "./default-tracking"
import DefaultEasyApplyButton from "./default-easy-apply-button"
import DefaultRegularApplyButton from "./default-regular-apply-button"
import DefaultEasyApplyModal from "./default-easy-apply-modal/default-easy-apply-modal"
import DefaultApplyButton from "./default-apply-button"

const DefaultHeader = dynamic(() => import("./default-header"))
const DefaultFooter = dynamic(() => import("./default-footer"))
const DefaultLayout = dynamic(() => import("./default-layout"))

const DefaultComponents = {
  Header: DefaultHeader,
  Footer: DefaultFooter,
  Layout: DefaultLayout,
  ApplyButton: DefaultApplyButton,
  RegularApplyButton: DefaultRegularApplyButton,
  EasyApplyButton: DefaultEasyApplyButton,
  EasyApplyModal: DefaultEasyApplyModal,
  PartnersWithMostJobs: DefaultPartnersWithMostJobs,
  PartnersWithMostJobsHeader: DefaultPartnersWithMostJobsHeader,
  JobList: DefaultJobList,
  JobCard: DefaultJobCard,
  CompanyInfo: DefaultCompanyInfo,
  CompanyCard: DefaultCompanyCard,
  JobInfo: DefaultJobInfo,
  JobDetails: DefaultJobDetails,
  JobDescription: DefaultJobDescription,
  JobSidebar: DefaultJobSidebar,
  SimilarJobs: DefaultSimilarJobs,
  JobShare: DefaultJobShare,
  CompaniesList: DefaultCompaniesList,
  JobsFilter: DefaultJobsFilter,
  Hero: DefaultHero,
  Meta: DefaultMeta,
  Tracking: DefaultTracking,
  InactiveJob: DefaultInactiveJob,
}

export default DefaultComponents
