import React from "react"
import { Label } from "reactstrap"

interface LabelWithHintProps {
  label: React.ReactNode
  hint: React.ReactNode
  isRequired?: boolean
  for?: string
}

export const LabelWithHint: React.FC<LabelWithHintProps> = props => {
  if (!props.label && !props.hint) {
    return null
  }

  return (
    <div className="d-flex mb-2">
      {props.label && (
        <Label for={props.for} className="m-0">
          {props.label}
          {props.isRequired && "*"}
        </Label>
      )}
      {props.hint && <span>{props.hint}</span>}
    </div>
  )
}
