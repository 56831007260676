import React from "react"
import classNames from "classnames"

import styles from "./step-progress.module.scss"

const cn = classNames.bind(styles)

interface Step {
  label: string
  active: boolean
  passed: boolean
}

interface StepProgressProps {
  steps: Step[]
}

export const StepProgress: React.FC<StepProgressProps> = ({ steps }) => {
  return (
    <div className={styles.steps}>
      {steps.map(({ active, passed, label }) => {
        return (
          <div
            key={label}
            className={cn([
              styles.steps__step,
              {
                [styles.steps__step_passed]: passed,
                [styles.steps__step_current]: active,
                [styles.steps__step_todo]: !active && !passed,
              },
            ])}
          >
            <span>
              {passed ? <i className="fas fa-check text-white" /> : label}
            </span>
          </div>
        )
      })}
    </div>
  )
}
