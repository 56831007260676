import React from "react"
import { FormattedMessage } from "react-intl"
import { Container } from "reactstrap"
import DefaultLayout from "../default/default-layout"
import { Img } from "./img"

export const Error: React.FC<{ errorCode: number }> = ({ errorCode }) => {
  if (errorCode === 404) {
    return (
      <DefaultLayout>
        <Container className="align-items-center d-flex flex-column justify-content-center flex-grow-1">
          <span className="display-4 text-center">
            <small>
              <FormattedMessage
                id="shared.error.notFound"
                defaultMessage="Oops, one or several resources that you requested could not be
                found :("
              />
            </small>
          </span>
          <Img width="50%" src="/web-catalog-app/assets/404.svg" alt="404" />
        </Container>
      </DefaultLayout>
    )
  }
  return (
    <DefaultLayout>
      <Container className="align-items-center d-flex flex-column">
        <h3 className="py-5">Oops, something went wrong!</h3>
        <img src="/web-catalog-app/assets/error.svg" alt="error" width="50%" />
      </Container>
    </DefaultLayout>
  )
}
