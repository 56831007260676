import React from "react"
import { FormattedMessage } from "react-intl"
import { Job } from "../../lib/types/moberries-entities"

interface DefaultJobDescriptionProps {
  job: Job
}

const DefaultJobDescription: React.FC<DefaultJobDescriptionProps> = ({
  job,
}) => {
  return (
    <div className="box p-3 mt-2 border rounded">
      <h4>
        <FormattedMessage
          id="default.jobDescription.header"
          defaultMessage="Description"
        />
      </h4>
      <div
        className="text-break pr-md-5 pr-lg-7"
        dangerouslySetInnerHTML={{ __html: job.description }}
      />
    </div>
  )
}

export default DefaultJobDescription
