import { useRouter } from "next/router"
import React, { useContext } from "react"
import { FormattedMessage } from "react-intl"
import { Button } from "reactstrap"
import { CompanyGroupContext } from "../../lib/company-group-context"
import { getApplyUrl, setJobliftIntentTracker } from "../../lib/helpers"

const DefaultRegularApplyButton: React.FC = () => {
  const companyGroup = useContext(CompanyGroupContext)

  const router = useRouter()
  const {
    utm_source: source,
    jl_tid: trackerId,
    jg_clickid: clickId,
    id: jobId,
  } = router.query

  const clickHandler = () => {
    if (source === "joblift") {
      setJobliftIntentTracker()
    }
  }

  const applyUrl = getApplyUrl({
    source,
    jobId,
    companyGroupId: companyGroup?.id,
    trackerId,
    clickId,
  })

  return (
    <Button
      block
      color="primary"
      onClick={clickHandler}
      href={applyUrl}
      tag="a"
      target="_blank"
    >
      <FormattedMessage
        id="default.jobSidebar.applyNow"
        defaultMessage="Apply now"
      />
    </Button>
  )
}

export default DefaultRegularApplyButton
