import { createContext, useCallback, useState } from "react"

export type TrackingLabelContextProps = {
  trackingLabel: string | null
  setTrackingLabel: (name: string | null) => void
}

export const TrackingLabelContext = createContext<TrackingLabelContextProps>({
  trackingLabel: null,
  setTrackingLabel: () => null,
})

export const TrackingLabelProvider: React.FC = ({ children }) => {
  const [label, setLabel] = useState<string | null>(null)
  const setTrackingLabel = useCallback(
    newLabel => {
      if (!label && newLabel) {
        setLabel(newLabel)
      }
    },
    [label],
  )

  return (
    <TrackingLabelContext.Provider
      value={{ trackingLabel: label, setTrackingLabel }}
    >
      {children}
    </TrackingLabelContext.Provider>
  )
}
