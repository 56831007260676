import React from "react"
import { Company } from "../../lib/types/moberries-entities"
import { Row, Col } from "reactstrap"
import { useComponents } from "../../hooks/use-components"
import { Img } from "../shared/img"

interface DefaultCompaniesListProps {
  companies: Company[]
}

const DefaultCompaniesList: React.FC<DefaultCompaniesListProps> = ({
  companies,
}) => {
  const { CompanyCard } = useComponents()
  return (
    <Row>
      {companies.length === 0 && (
        <Col xs={{ offset: 3, size: 6 }} className="mt-5">
          <Img src="/web-catalog-app/assets/empty-state.svg" />
        </Col>
      )}
      {companies.map(company => (
        <Col xs={12} md={6} lg={4} className="py-1" key={company.id}>
          <CompanyCard company={company} />
        </Col>
      ))}
    </Row>
  )
}

export default DefaultCompaniesList
