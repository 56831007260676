import React from "react"
import { components } from "react-select"

export const MenuListPoweredByGoogle = (props: any) => (
  <>
    <components.MenuList {...props} />
    {props.options.length > 0 && (
      <div className="d-flex flex-row-reverse px-2 py-2">
        <p>Powered by Google</p>
      </div>
    )}
  </>
)
