import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { JobTypes } from "../../lib/types/moberries-entities"

export const JobType: React.FC<{ jobType: JobTypes }> = ({ jobType }) => (
  <Fragment>
    {
      {
        [JobTypes.FullTime]: (
          <FormattedMessage
            id="shared.jobType.fullTime"
            defaultMessage="Full time"
          />
        ),
        [JobTypes.PartTime]: (
          <FormattedMessage
            id="shared.jobType.partTime"
            defaultMessage="Part time"
          />
        ),
        [JobTypes.Contractor]: (
          <FormattedMessage
            id="shared.jobType.contract"
            defaultMessage="Contract"
          />
        ),
        [JobTypes.Intern]: (
          <FormattedMessage
            id="shared.jobType.intern"
            defaultMessage="Intern"
          />
        ),
        [JobTypes.Temporary]: (
          <FormattedMessage
            id="shared.jobType.temporary"
            defaultMessage="Temporary"
          />
        ),
      }[jobType]
    }
  </Fragment>
)
