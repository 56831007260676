import RSelect, { Props } from "react-select"

export const Select: React.FC<Props<any>> = ({ id, ...props }) => (
  <RSelect
    components={{
      IndicatorSeparator: null,
    }}
    id={id}
    instanceId={id}
    classNamePrefix="select"
    {...props}
  />
)
