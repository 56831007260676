import isIP from "is-ip"
import { IncomingMessage } from "http"
import { Board, BoardLayoutTypes, BoardDataTypes } from "../types/boards"
import { Locales } from "../types/shared"
import { config } from "../config"

function getSubdomainFromRequest(req: IncomingMessage): string | null {
  if (!req.headers.host) {
    return null
  }

  if (req.headers.host.includes(":")) {
    return null
  }

  if (isIP(req.headers.host)) {
    return null
  }

  const hostParts = req.headers.host.split(".")
  if (hostParts.length <= 2) {
    return null
  }

  return hostParts[0]
}

export function getBaseUrl(board: Board) {
  return `${config.urls.homepage!.replace("www", board.subdomain)}`
}

export function getBoard(req?: IncomingMessage): Board {
  if (!req) {
    return {
      layoutType: BoardLayoutTypes.Default,
      dataType: BoardDataTypes.Common,
      subdomain: "",
    }
  }
  const subdomain = getSubdomainFromRequest(req)

  switch (subdomain) {
    case null:
    case undefined:
    case "":
    case "www":
    case "jobs":
      return {
        layoutType: BoardLayoutTypes.Default,
        dataType: BoardDataTypes.Common,
        subdomain: "",
      }

    case "bestcareer": {
      return {
        layoutType: BoardLayoutTypes.BestCareer,
        dataType: BoardDataTypes.Default,
        subdomain,
      }
    }

    case "lhoft": {
      return {
        layoutType: BoardLayoutTypes.Lhoft,
        dataType: BoardDataTypes.Default,
        subdomain,
      }
    }

    case "remote":
      return {
        layoutType: BoardLayoutTypes.Remote,
        dataType: BoardDataTypes.Remote,
        subdomain,
      }

    case "htgf":
      return {
        layoutType: BoardLayoutTypes.Htgf,
        dataType: BoardDataTypes.Default,
        subdomain,
      }

    case "redstone":
      return {
        layoutType: BoardLayoutTypes.Redstone,
        dataType: BoardDataTypes.Default,
        subdomain,
      }

    case "talent-berlin":
      return {
        layoutType: BoardLayoutTypes.TalentBerlin,
        dataType: BoardDataTypes.TalentBerlin,
        subdomain,
      }

    default: {
      return {
        layoutType: BoardLayoutTypes.Default,
        dataType: BoardDataTypes.Default,
        subdomain,
      }
    }
  }
}

export function getBoardDefaultLocale(board: Board): Locales {
  if (board.layoutType === BoardLayoutTypes.BestCareer) {
    return Locales.DE
  }
  return Locales.EN
}
