import React, { Fragment } from "react"
import { useComponents } from "../../hooks/use-components"
const DefaultLayout: React.FC = ({ children }) => {
  const { Header, Footer } = useComponents()
  return (
    <Fragment>
      <Header />
      <main className="main">{children}</main>
      <Footer />
    </Fragment>
  )
}

export default DefaultLayout
