import dynamic from "next/dynamic"
import DefaultComponents from "../default/default-components"

const HtgfHeader = dynamic(() => import("./htgf-header"))
const HtgfHero = dynamic(() => import("./htgf-hero"))
const HtgfFooter = dynamic(() => import("./htgf-footer"))

const HtgfComponents = {
  ...DefaultComponents,
  Header: HtgfHeader,
  Footer: HtgfFooter,
  Hero: HtgfHero,
}

export default HtgfComponents
